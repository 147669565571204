@import './styles.scss';
@import './App.scss';

.movie-list {
  padding: 0 5px;
  margin-top: 20px;
  text-align: justify;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 33%;
  .movie-card {
    width: 150px;
    height: 220px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* aspect-ratio: 2/3; */
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.045);
    border-radius: 10px;
    overflow: hidden;
    opacity: 0.7;
    -webkit-transition: opacity 200ms ease-in-out;
    -moz-transition: opacity 200ms ease-in-out;
    -ms-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;
    &:hover {
      background-color: rgba(255, 255, 255, 0.121);
      opacity: 1;
    }
    .movie-card-content{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      .video-name {
        margin: 4px;
        padding: 5px;
        font-size: 0.7rem;
        background-color: #ffffff1a;
        border-radius: 8px;
        height: auto;
        overflow-wrap: break-word;
        hyphens: auto;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: auto;
        -webkit-box-orient: vertical;
        text-shadow: 1px 1px 5px #000;
        // box-shadow: 0px 2px 11px 1px rgba(119, 119, 119, 0.563);
        background-color: #00000014;
        font-weight: bold;
        p {
          color: white;
        }
        .is-sub {
          color: yellow;
        }
        
      }
      .delete-btn {
        width: 100%;
        margin: 0;
        padding: 5px;
        color: white;
        background-color: rgb(161, 68, 86);
        font-size: 1rem;
        opacity: 0.2;
        -webkit-transition: opacity 200ms ease-in-out;
        -moz-transition: opacity 200ms ease-in-out;
        -ms-transition: opacity 200ms ease-in-out;
        -o-transition: opacity 200ms ease-in-out;
        transition: opacity 200ms ease-in-out;
        &:hover {
          cursor: pointer;
          color: white;
          opacity: 0.6;
        }
      }
    }
  }
}
