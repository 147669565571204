.container {
  height: 100vh;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
}

.movie-card {
  width: var(--find-movie-width, 400px);
  aspect-ratio: 2 / 3;

  .skeleton {
    padding: 20px;
    // background: #ffffff;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);

    .square {
      padding: 15px;
      border-radius: 5px;
      background: rgba(130, 130, 130, 0.2);
      background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
      background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
      background-size: 800px 100px;
      animation: wave-squares 2s infinite ease-out;
    }

    .line {
      height: 12px;
      margin-bottom: 6px;
      border-radius: 2px;
      background: rgba(130, 130, 130, 0.2);
      background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
      background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
      background-size: 800px 100px;
      animation: wave-lines 2s infinite ease-out;

      &:last-child {
        margin-bottom: 0;
      }
    }  
  } // skelton

  .flex {
    display: flex;
    gap: 5px;
  }

  .col {
    flex-direction: column;
    justify-content: space-between;
  }

  .h8 {
    height: 8px !important;
  }
  .h10 {
    height: 10px !important;
  }
  .h12 {
    height: 12px !important;
  }
  .h15 {
    height: 15px !important;
  }
  .h17 {
    height: 17px !important;
  }
  .h20 {
    height: 20px !important;
  }
  .h25 {
    height: 25px !important;
  }
  .h55 {
    height: 55px !important;
  }

  .w25 {
    width: 25% !important
  }
  .w40 {
    width: 40% !important;
  }
  .w50 {
    width: 50% !important
  }
  .w75 {
    width: 75% !important
  }
  .m10 {
    margin-bottom: 10px !important;
  }
  .m20 {
    margin-bottom: 20px !important;
  }
  
  .w100 {
    width: 100%;
  }

  .h100 {
    height: 100%;
  }

  @keyframes wave-lines {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  @keyframes wave-squares {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
}