@import './styles.scss';

.input-movie {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  gap: 5px;

  input {
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: center;
  }

  input[type=file] {
    color: rgb(72, 72, 72);
    background-color: rgb(241, 241, 241);
  }

  button {
    font-size: 1.2rem;
    outline: none;
    padding: 5px 10px;
    color: white;
    background-color: rgb(75, 152, 162);

    &:hover {
      opacity: 1;
    }
  }

  span {
    color: whitesmoke;
  }

  .url-section {
    // padding: 0 10px;
    // margin-top: 10px;
    display: flex;
    justify-content: center;

    .input {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 5px;

      .video-url {
        flex-grow: 1.2;
        font-size: 1.2rem;
        padding: 10px;
        outline: none;
        background-color: whitesmoke;
      }
    }
  }

  .movie-section {
    // padding: 5px 10px 0px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    gap: 5px;

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }

    .new-name {
      padding: 7px;
      font-size: 1rem;
    }

    .type-of-search {
      display: flex;
      gap: 10px;
      justify-content: space-between;

      div {
        display: flex;
        gap: 5px;
      }

    }

    .movie-name-guesses {
      padding: 0;
      gap: 10px;
      justify-self: baseline;

      .movie-name {
        transition: all 250ms ease;
        scale: 1;

        &:hover {
          transition: all 250ms ease;
          scale: 1.05;
          cursor: pointer;
        }

        button {
          border-radius: 5px;
          padding: 2px 10px;
        }
      }
    }
  }

  .find-movie-section {

    display: flex;
    flex-direction: column;
    align-items: center;

    .loading, 
    .load-more {
      width: var(--find-movie-width, 400px);
      padding: 20px;
      font-weight: bold;
      border-radius: 10px;
      color: #ffffff;
      background-color: #ffffff0d;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
    
    .loading {
      background-color: #ffffff0d;
      color: #fff94a;
    }

    .find-movie-list {
      margin-top: 10px;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0;
      align-items: center;

      
      .find-movie {
        width: var(--find-movie-width, 400px);
        border-radius: 10px;
        overflow: hidden;
        transition: all 250ms ease;
        aspect-ratio: 2/3;

        &:hover {}

        .poster {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          // justify-content: space-between;
          position: relative;

          .movie-data {
            padding: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;

            span {
              opacity: 0.6;
              font-weight: 600;
              padding: 2px 8px;
              border-radius: 5px;
              color: #ffffff;
              background-color: #000000c3;
            }

            .title {
              font-size: 1.4rem;
              opacity: 0.8;
            }

            .plot {
              font-size: 0.8rem;
            }


          }

          .file-data {
            display: flex;
            flex-direction: column;
            padding: 15px;
            gap: 3px;
            font-weight: bold;
            line-height: 30px;

            .section {

              &-column,
              &-row {
                display: flex;
                background: #00000060;
                border-radius: 10px;
              }

              &-column {
                gap: 5px;
                flex-direction: column;
              }

              &-row {
                flex-direction: row;
                gap: 7px;
              }
            }

            .file-name {
              display: flex;
              padding: 5px;

              input {
                padding: 0;
                text-align: center;
                flex-grow: 1;
              }
            }

            .season-episode {
              padding: 5px;
              justify-content: space-between;

              .title {}

              .picker {
                display: flex;
                gap: 5px;
                button,

                span {
                  outline: none;
                  border-radius: 5px;
                  font-weight: bold;
                }

                button {
                  width: 35px;
                  color: white;
                  background-color: rgba(255, 255, 255, 0.284);
                  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
                  // border: 1px solid white;
                }

                span {
                  width: 50px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  background-color: #ffffff6f;
                  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
                  // border: 1px solid gray;
                }
              }
            }

            .file-type {
              padding: 5px;
              justify-content: space-between;

              .title {
                flex-grow: 1;
              }

              .selected {
                box-shadow: 1px 1px 20px 0px rgba(136, 136, 136, 0.5);
                background-color: #5aa3f1;
              }

              button {
                font-weight: bold;
                line-height: 22px;
                border-radius: 7px;
                background-color: rgba(255, 255, 255, 0.27);
              }

              .search-subtitle-btn {
                width: 100%;
                font-weight: bold;
                background-color: rgb(203, 36, 190);
                // border: 1px solid #ffffffd5;
                color: #ffffff;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
              }
            }

            .vtt-selected {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .vtt-file {
                padding: 5px;
                font-size: 10px;
                line-height: normal;
                line-break: anywhere;
                color: #fffb20;
              }
            }
          }

          .add-button {
            position: absolute;
            bottom: 0;
            padding: 15px;
            width: 100%;

            .add-btn {
              width: 100%;
              border-radius: 5px;
              background-color: rgb(0, 166, 50);
            }
          }
        }
      }
    }

    .page-number {
      margin-top: 10px;
      padding: 0;
      display: flex;
      justify-content: center;
      list-style-type: none;
      gap: 10px;
      color: #fff;

      .page {
        cursor: pointer;
      }
    }
  }

  .sub-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;

    li {
      padding: 4px;
      transition: all 250ms ease;

      &:hover {
        transition: all 250ms ease;
        /* scale: 1.05; */
        cursor: pointer;
        color: white;
        background-color: rgb(120, 56, 131);
      }
    }

    .subtitle-nothing-found {
      color: #fff700;
      text-align: center;
      // text-shadow: -1px 1px 6px rgba(0, 0, 0, 0.7);
    }

    .subtitles-select,
    .vtt-select {
      width: -webkit-fill-available;
      font-size: .9rem;
      outline: none;
      padding: 5px;
      color: #fff777;
      background-color: slategray;
      border-radius: 5px;
    }
  }
}